<template>
  <div
    class="bg-[#151823] w-full h-32 flex justify-center py-9 font-inter duration-300 fixed top-0 sm:static"
  >
    <div
      class="container flex flex-col px-4 sm:px-0 gap-10 relative"
      v-if="!isMobileMenuOpen"
    >
      <div class="flex justify-between">
        <div class="flex items-center gap-5">
          <img src="@/assets/logo.svg" alt="" />
          <img src="@/assets/text_logo.svg" alt="" />
        </div>
        <div class="gap-5 items-center text-white hidden lg:flex">
          <a href="">+7 961 346 70 77</a>
          <a href="https://www.instagram.com/lider_prava/">
            <img src="@/assets/instagram_icon.svg" alt="" />
          </a>
          <button
            class="bg-[#4F80E1] py-4 px-6 rounded-[4px]"
            @click="scrollToElementDesktop('form')"
          >
            ЗАКАЗАТЬ КОНСУЛЬТАЦИЮ
          </button>
        </div>
        <button
          class="flex items-center lg:hidden"
          @click="isMobileMenuOpen = !isMobileMenuOpen"
        >
          <img v-if="!isMobileMenuOpen" src="@/assets/burger.svg" alt="" />
          <img v-else src="@/assets/square-rounded-x.svg" alt="" />
        </button>
      </div>
    </div>
  </div>
  <div
    data-aos="fade-down"
    class="flex flex-col items-start absolute bg-[#151823] w-full h-full top-0 px-4 duration-300 pb-10"
    v-if="isMobileMenuOpen"
  >
    <div class="flex justify-between w-full h-32 py-9">
      <div class="flex items-center gap-5">
        <img src="@/assets/logo.svg" alt="" />
        <img src="@/assets/text_logo.svg" alt="" />
      </div>
      <div class="gap-5 items-center text-white hidden lg:flex">
        <a href="">+7 961 346 70 77</a>
        <a href="https://www.instagram.com/lider_prava/">
          <img src="@/assets/instagram_icon.svg" alt="" />
        </a>
        <button
          class="bg-[#4F80E1] py-4 px-6 rounded-[4px]"
          @click="scrollToElementDesktop('form')"
        >
          ЗАКАЗАТЬ КОНСУЛЬТАЦИЮ
        </button>
      </div>
      <button
        class="flex items-center lg:hidden"
        @click="isMobileMenuOpen = !isMobileMenuOpen"
      >
        <img v-if="!isMobileMenuOpen" src="@/assets/burger.svg" alt="" />
        <img v-else src="@/assets/square-rounded-x.svg" alt="" />
      </button>
    </div>
    <div class="flex flex-col flex-grow justify-between">
      <ul
        class="flex flex-col gap-4 text-base font-medium font-montserrat text-white"
      >
        <li @click="scrollToElement('who')">Кто мы</li>
        <li @click="scrollToElement('uslugi')">Виды услуг</li>
        <li @click="scrollToElement('form')">Оставить заявку</li>
        <li @click="scrollToElement('contacts')">Контакты</li>
      </ul>
      <div class="flex flex-col gap-4">
        <h1 class="text-white font-montserrat font-bold">Контакты:</h1>
        <a
          href="tel:+79613467077"
          class="text-white font-montserrat font-bold flex flex-row gap-2"
          ><img src="@/assets/phone.svg" alt="" class="h-5 w-5" /> +7 961 346 70
          77</a
        >
        <a
          href="https://www.instagram.com/lider_prava/"
          class="text-white font-montserrat font-bold flex flex-row gap-2"
          ><img src="@/assets/instagram_icon.svg" alt="" class="h-5 w-5" />
          lider_prava</a
        >
      </div>
    </div>
  </div>

  <div
    class="bg-layout bg-cover bg-center bg-no-repeat h-screen w-full mt-32 sm:mt-0"
  >
    <div
      class="flex justify-center h-full w-full bg-gradient-to-r from-black via-transparent to-transparent"
    >
      <div class="container w-full pt-24 flex justify-start font-montserrat">
        <form
          class="flex flex-col justify-start w-full lg:w-1/3 gap-6 text-base px-4"
        >
          <input
            v-model="name"
            @input="resetError('name')"
            type="text"
            name="name"
            placeholder="Имя"
            class="h-14 rounded-[4px] p-4"
            :class="{ 'border-red-500': errors.name }"
          />
          <span v-if="errors.name" class="text-red-500 text-sm"
            >Поле Имя не может быть пустым</span
          >

          <input
            v-model="phone"
            @input="resetError('phone')"
            type="tel"
            name="phone"
            placeholder="Телефон"
            class="h-14 rounded-[4px] p-4"
            :class="{ 'border-red-500': errors.phone }"
          />
          <span v-if="errors.phone" class="text-red-500 text-sm"
            >Поле Телефон не может быть пустым</span
          >

          <input
            v-model="email"
            @input="resetError('email')"
            type="email"
            name="email"
            placeholder="E-mail"
            class="h-14 rounded-[4px] p-4"
            :class="{ 'border-red-500': errors.email }"
          />
          <span v-if="errors.email" class="text-red-500 text-sm"
            >Поле E-mail не может быть пустым</span
          >
          <span v-if="isInvalidEmail" class="text-red-500 text-sm">
            Неверный формат электронной почты
          </span>

          <ListBox @change="selectAppeal" />

          <button
            type="button"
            @click="sendParams"
            class="w-full font-inter bg-[#4F80E1] py-4 px-6 rounded-[4px] text-white"
          >
            Оставить заявку
          </button>
        </form>
      </div>
    </div>
  </div>
  <div class="bg-[#F7F5F5] sm:py-36 py-16 flex justify-center w-full" id="who">
    <div
      class="container w-full flex-col flex justify-center items-center font-montserrat gap-10"
    >
      <div class="flex flex-col justify-center items-center">
        <h1 class="text-3xl lg:text-6xl font-semibold">Кто мы</h1>
        <p class="text-xl text-[#828D98] font-light text-left px-4">
          Наша позиция это честность и открытость юридических услуг
        </p>
      </div>
      <div
        class="w-full flex items-center gap-4 flex-col lg:flex-row px-4 lg:px-0"
      >
        <div
          class="w-full lg:w-4/6 flex flex-col justify-start items-start font-montserrat gap-4"
        >
          <h1 class="font-bold text-xl">
            Мы компания, которая заботится о своей репутации
          </h1>
          <div class="flex items-center justify-center gap-4">
            <img src="@/assets/scales.svg" alt="" />
            <p>
              <span class="font-bold">Наши основные ценности</span> –
              максимальная ориентированность на потребности клиента и
              нацеленность на результат.
            </p>
          </div>
          <div class="flex items-center justify-center gap-4">
            <img src="@/assets/hammer.svg" alt="" />
            <p>
              <span class="font-bold">Преимущества компании</span> – высокая
              компетенция в сферах специализации, профессиональная команда
              единомышленников, лояльная ценовая политика, безупречная деловая
              репутация.
            </p>
          </div>
        </div>
        <div class="w-full lg:w-2/4">
          <img
            src="@/assets/court.jpg"
            alt=""
            class="lg:max-w-[400px] rounded-[39px]"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="bg-white sm:py-36 py-16 flex justify-center w-full">
    <div
      class="container w-full flex-col flex justify-center items-center font-montserrat gap-10"
    >
      <div
        class="flex flex-col justify-center items-center gap-10 px-4 lg:px-0"
      >
        <h1 class="text-xl font-bold">
          Комплексный подход к решению юридических вопросов. Возможность работы
          по результату. Бесплатная консультация
        </h1>
        <div class="grid grid-cols-1 lg:grid-cols-2 w-full lg:w-2/3 gap-4">
          <p
            class="w-full lg:w-auto text-xl text-[#828D98] font-light flex items-center justify-start gap-3"
            v-for="advantage in advantages"
            :key="advantage"
          >
            <img src="@/assets/approve.svg" alt="" />
            {{ advantage }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-white sm:py-36 py-16 flex justify-center w-full" id="uslugi">
    <div
      class="container w-full flex-col flex justify-start items-start font-montserrat px-4 lg:px-0"
    >
      <h1 class="text-3xl lg:text-6xl font-bold mb-10">Виды услуг</h1>
      <div
        class="flex flex-col w-full border-b border-[#B0B0B0] hover:bg-[#E8EDF6] duration-300"
        :class="tab.active ? 'bg-[#E8EDF6] py-8' : 'py-4'"
        v-for="tab in tabs"
        :key="tab"
      >
        <div
          class="w-full flex flex-row justify-between px-4 cursor-pointer"
          @click="tab.content.length > 0 ? switchActiveTab(tab) : ''"
        >
          <p class="font-bold">{{ tab.button_text }}</p>
          <button v-if="tab.content.length > 0">
            <img
              src="@/assets/arrow.svg"
              alt=""
              :class="tab.active ? 'rotate-180' : ''"
            />
          </button>
        </div>

        <div
          v-if="tab.active"
          class="flex flex-col justify-start items-start px-4 mt-4"
        >
          <p
            v-for="advantage in tab.content"
            :key="advantage"
            class="flex flex-row items-center justify-center gap-4 text-[#5A5E78] text-sm"
          >
            <span class="h-1 w-1 rounded-full bg-[#5A5E78]"></span>
            {{ advantage }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-[#151823] sm:py-36 py-16 flex justify-center w-full" id="form">
    <div
      class="container w-full flex-col flex justify-center items-center font-montserrat gap-10 px-4 lg:px-0"
    >
      <div class="flex flex-col justify-center items-center">
        <h1 class="text-3xl lg:text-6xl font-bold text-white">
          Оставить заявку
        </h1>
        <p class="text-xl text-[#828D98] font-light text-center">
          Мы с вами свяжемся
        </p>
      </div>
      <form class="flex flex-col justify-start w-full lg:w-1/3 gap-6 text-base">
        <input
          v-model="name"
          @input="resetError('name')"
          type="text"
          name="name"
          placeholder="Имя"
          class="h-14 rounded-[4px] p-4"
          :class="{ 'border-red-500': errors.name }"
        />
        <span v-if="errors.name" class="text-red-500 text-sm"
          >Поле Имя не может быть пустым</span
        >

        <input
          v-model="phone"
          @input="resetError('phone')"
          type="tel"
          name="phone"
          placeholder="Телефон"
          class="h-14 rounded-[4px] p-4"
          :class="{ 'border-red-500': errors.phone }"
        />
        <span v-if="errors.phone" class="text-red-500 text-sm"
          >Поле Телефон не может быть пустым</span
        >

        <input
          v-model="email"
          @input="resetError('email')"
          type="email"
          name="email"
          placeholder="E-mail"
          class="h-14 rounded-[4px] p-4"
          :class="{ 'border-red-500': errors.email }"
        />
        <span v-if="errors.email" class="text-red-500 text-sm"
          >Поле E-mail не может быть пустым</span
        >
        <span v-if="isInvalidEmail" class="text-red-500 text-sm">
          Неверный формат электронной почты
        </span>

        <ListBox @change="selectAppeal" />

        <button
          type="button"
          @click="sendParams"
          class="w-full font-inter bg-[#4F80E1] py-4 px-6 rounded-[4px] text-white"
        >
          Оставить заявку
        </button>
      </form>
    </div>
  </div>
  <div class="bg-white sm:py-36 py-16 flex justify-center w-full" id="contacts">
    <div
      class="container w-full flex-col lg:flex-row flex justify-center items-center font-montserrat gap-10 px-4 lg:px-0"
    >
      <div
        class="w-full lg:w-1/2 flex flex-col items-start justify-start gap-4"
      >
        <h1 class="font-bold text-3xl lg:text-6xl">Контакты</h1>
        <a
          href="mailto:liderprava21@ya.ru"
          class="flex flex-row gap-4 text-[#595959] items-center justify-center cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="20"
            viewBox="0 0 23 20"
            fill="none"
          >
            <path
              d="M1.13636 0H21.5909C21.8923 0 22.1814 0.117067 22.3944 0.325433C22.6075 0.533811 22.7273 0.816422 22.7273 1.11111V18.8889C22.7273 19.1836 22.6075 19.4662 22.3944 19.6746C22.1814 19.8829 21.8923 20 21.5909 20H1.13636C0.834977 20 0.545943 19.8829 0.33283 19.6746C0.119727 19.4662 0 19.1836 0 18.8889V1.11111C0 0.816422 0.119727 0.533811 0.33283 0.325433C0.545943 0.117067 0.834977 0 1.13636 0ZM20.4545 4.70889L11.4455 12.5978L2.27273 4.68444V17.7778H20.4545V4.70889ZM2.85341 2.22222L11.433 9.62444L19.8886 2.22222H2.85341Z"
              fill="#4F80E1"
            />
          </svg>
          ИП Артемьев Артём Сергеевич liderprava21@ya.ru
        </a>
        <a
          href="tel:+79613467077"
          class="flex flex-row gap-4 text-[#595959] items-center justify-center cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="24"
            viewBox="0 0 23 24"
            fill="none"
          >
            <path
              d="M22.9987 17.618V22.1362C22.9989 22.4597 22.8763 22.7713 22.6558 23.0079C22.4353 23.2446 22.1332 23.3888 21.8105 23.4114C21.2521 23.4498 20.796 23.4702 20.4433 23.4702C9.15221 23.4702 0 14.3175 0 3.02577C0 2.6731 0.0191656 2.21694 0.0587745 1.65855C0.0814185 1.33584 0.225619 1.03373 0.462279 0.813182C0.698939 0.592635 1.01044 0.470069 1.33393 0.470215H5.8519C6.01038 0.470055 6.16327 0.528812 6.28086 0.635074C6.39845 0.741336 6.47235 0.887515 6.4882 1.04521C6.51758 1.3391 6.54441 1.57294 6.56997 1.75055C6.82389 3.52272 7.34426 5.24633 8.11344 6.86294C8.23482 7.11849 8.1556 7.42388 7.92562 7.58744L5.16832 9.55777C6.85419 13.4863 9.9847 16.6169 13.9129 18.3029L15.8806 15.5505C15.961 15.4381 16.0784 15.3575 16.2122 15.3227C16.346 15.2878 16.4878 15.3011 16.6127 15.3602C18.229 16.128 19.9522 16.647 21.7236 16.8999C21.9012 16.9254 22.135 16.9535 22.4263 16.9817C22.5837 16.9978 22.7296 17.0719 22.8357 17.1894C22.9416 17.307 22.999 17.4597 22.9987 17.618Z"
              fill="#4F80E1"
            />
          </svg>
          +7 961 346 70 77
        </a>
        <p
          class="flex flex-row gap-4 text-[#595959] items-center justify-center cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="min-w-[23px] w-[23px]"
            viewBox="0 0 23 29"
            fill="none"
          >
            <path
              d="M11.5 25.3259L17.825 18.947C19.0758 17.6854 19.9276 16.078 20.2727 14.3283C20.6177 12.5784 20.4405 10.7647 19.7635 9.11649C19.0866 7.46823 17.94 6.05945 16.4692 5.06829C14.9983 4.07712 13.269 3.5481 11.5 3.5481C9.73104 3.5481 8.00173 4.07712 6.53084 5.06829C5.05995 6.05945 3.91351 7.46823 3.23651 9.11649C2.55949 10.7647 2.38231 12.5784 2.72735 14.3283C3.0724 16.078 3.9242 17.6854 5.175 18.947L11.5 25.3259ZM11.5 28.9702L3.36822 20.7691C1.75993 19.1471 0.664686 17.0806 0.220965 14.8308C-0.222756 12.581 0.0049949 10.2491 0.875405 8.12981C1.74581 6.01056 3.21979 4.19921 5.11096 2.92482C7.00212 1.65042 9.22556 0.970215 11.5 0.970215C13.7744 0.970215 15.9979 1.65042 17.889 2.92482C19.7803 4.19921 21.2542 6.01056 22.1246 8.12981C22.995 10.2491 23.2227 12.581 22.7791 14.8308C22.3353 17.0806 21.2401 19.1471 19.6318 20.7691L11.5 28.9702ZM11.5 15.1454C12.1777 15.1454 12.8277 14.8739 13.307 14.3905C13.7863 13.9073 14.0556 13.2517 14.0556 12.5681C14.0556 11.8846 13.7863 11.229 13.307 10.7457C12.8277 10.2623 12.1777 9.99077 11.5 9.99077C10.8223 9.99077 10.1723 10.2623 9.69297 10.7457C9.21367 11.229 8.94444 11.8846 8.94444 12.5681C8.94444 13.2517 9.21367 13.9073 9.69297 14.3905C10.1723 14.8739 10.8223 15.1454 11.5 15.1454ZM11.5 17.7227C10.1444 17.7227 8.84442 17.1797 7.88591 16.2129C6.92738 15.2463 6.38889 13.9352 6.38889 12.5681C6.38889 11.201 6.92738 9.8899 7.88591 8.9232C8.84442 7.95652 10.1444 7.41344 11.5 7.41344C12.8556 7.41344 14.1556 7.95652 15.1141 8.9232C16.0727 9.8899 16.6111 11.201 16.6111 12.5681C16.6111 13.9352 16.0727 15.2463 15.1141 16.2129C14.1556 17.1797 12.8556 17.7227 11.5 17.7227Z"
              fill="#4F80E1"
            />
          </svg>
          428003, Чувашская Республика, г. Чебоксары, ул. Афанасьева, д. 2, оф.
          56. ОГРНИП 319213000057662 ИНН 210802556940
        </p>
        <a
          href="https://www.instagram.com/lider_prava/"
          class="text-[#595959] flex flex-row gap-2"
        >
          <IconBrandInstagram stroke="{4}" class="text-black w-[23px]" />
          lider_prava</a
        >
      </div>
      <div class="w-full lg:w-1/2">
        <div style="position: relative; overflow: hidden">
          <a
            href="https://yandex.ru/maps/org/lider_prava/34845163518/?utm_medium=mapframe&utm_source=maps"
            style="color: #eee; font-size: 12px; position: absolute; top: 0px"
            >Лидер Права</a
          ><a
            href="https://yandex.ru/maps/45/cheboksary/category/legal_services/184105630/?utm_medium=mapframe&utm_source=maps"
            style="color: #eee; font-size: 12px; position: absolute; top: 14px"
            >Юридические услуги в Чебоксарах</a
          ><a
            href="https://yandex.ru/maps/45/cheboksary/category/attorney/184105616/?utm_medium=mapframe&utm_source=maps"
            style="color: #eee; font-size: 12px; position: absolute; top: 28px"
            >Адвокаты в Чебоксарах</a
          ><iframe
            src="https://yandex.ru/map-widget/v1/?from=mapframe&ll=47.220842%2C56.146889&mode=search&oid=34845163518&ol=biz&z=17"
            frameborder="1"
            allowfullscreen="true"
            style="position: relative"
            class="rounded-3xl w-full min-h-[400px] lg:w-[560px] lg:h-[400px]"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-[#151823] py-20 flex justify-center w-full">
    <div
      class="container w-full flex-col lg:flex-row px-4 lg:px-0 flex justify-center items-end font-montserrat gap-10"
    >
      <div class="flex items-center gap-5">
        <img src="@/assets/logo.svg" alt="" />
        <img src="@/assets/text_logo.svg" alt="" />
      </div>
    </div>
  </div>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="closeModal" class="relative z-10">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/80" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div
          class="flex min-h-full items-center justify-center p-4 text-center"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
            >
              <DialogTitle
                as="h3"
                class="text-lg font-medium leading-6 text-gray-900"
              >
                Заявка успешна отправлена
              </DialogTitle>
              <div class="mt-2">
                <p class="text-sm text-gray-500">
                  Мы свяжемся с вами в ближайшее время
                </p>
              </div>

              <div class="mt-4 flex items-center justify-center">
                <button
                  type="button"
                  class="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                  @click="closeModal"
                >
                  Закрыть
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import axios from "axios";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";
import AOS from "aos";
import "aos/dist/aos.css";
import ListBox from "@/components/ListBox.vue";
import { IconBrandInstagram } from "@tabler/icons-vue";
export default {
  components: {
    IconBrandInstagram,
    ListBox,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
  },
  data() {
    return {
      isOpen: false,
      name: "",
      phone: "",
      email: "",
      errors: {
        name: false,
        phone: false,
        email: false,
      },
      isInvalidEmail: false,
      isMobileMenuOpen: false,
      advantages: [
        "Юрист по ДТП, автоюрист",
        "Защита прав потребителей",
        "Страховые споры",
        "Банкротство физических лиц",
        "Долевое участие в строительстве (взыскание неустойки)",
        "Трудовые споры",
        "Медицинские споры",
      ],
      tabs: [
        {
          button_text: "Защита прав потребителей",
          content: [
            "Возврат товара",
            "Обмен товара",
            "Возврат денег",
            "Взыскание неустойки",
            "Взыскание компенсации при некачественном гарантийном ремонте",
            "Возмещения вреда здоровью или имуществу",
            "Компенсация морального и материального ущерба",
            "Уменьшения оплаченной стоимости за товар или произведенную услугу",
            "Бесплатный ремонт, компенсация работы или услуги",
          ],
          active: false,
        },
        {
          button_text: "Взыскание ущерба, полученного в результате ДТП",
          content: [],
          active: false,
        },
        {
          button_text: "Страховые споры",
          content: [],
          active: false,
        },
        {
          button_text: "Банкротство физических лиц",
          content: ["Взыскание неустойки"],
          active: false,
        },
        {
          button_text: "Трудовые споры",
          content: [
            "Индивидуальный трудовой спор.",
            "Коллективный трудовой спор по ст. 398 ТК РФ",
            "Споры об использовании норм из Трудового кодекса",
            "Споры об совершенствовании действующих условий труда или введении новых",
            "Спор об выплате денежной компенсации и возмещении убытков",
            "Спор о признании прав, которые были нарушены другим субъектом",
          ],
          active: false,
        },
        {
          button_text: "Медицинские споры",
          content: [
            "Споры о медицинских ошибках",
            "Споры о качестве медицинской помощи",
            "Споры о медицинской страховке",
            "Споры о согласии на лечение",
            "Споры о конфиденциальности медицинских данных",
            "Споры о цене и оплате медицинских услуг",
            "Споры с медицинскими учреждениями и персоналом",
            "Споры о медицинских лицензиях",
            "Споры о правах пациентов",
            "Споры о медицинской этике и нормах",
          ],
          active: false,
        },
      ],
      appeal: "Записаться на приём",
    };
  },
  created() {
    AOS.init();
  },
  watch: {
    isMobileMenuOpen(newValue) {
      if (newValue === true) {
        // Добавляем класс для блокировки прокрутки
        document.body.classList.add("no-scroll");
      } else {
        // Удаляем класс при закрытии меню
        document.body.classList.remove("no-scroll");
      }
    },
    email(newEmail) {
      // Проверяем правильность формата email с помощью регулярного выражения
      this.isInvalidEmail = !this.validateEmail(newEmail);
    },
  },
  methods: {
    selectAppeal(param) {
      this.appeal = param.name;
    },
    validateEmail(email) {
      // Простая проверка формата email с помощью регулярного выражения
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    closeModal() {
      this.isOpen = false;
      window.location.reload();
    },
    resetError(fieldName) {
      // Сброс ошибки для указанного поля
      this.errors[fieldName] = false;
    },
    sendParams() {
      // Валидация перед отправкой
      if (this.name && this.phone && this.email && !this.isInvalidEmail) {
        // Данные формы
        const formData = {
          name: this.name,
          phone: this.phone,
          email: this.email,
        };
        // Токен и ID чата бота Telegram
        const botToken = "6965334844:AAF2oo4xoVwhcqNVh6MFu19ZEcAf9rTdZ5w";
        const chatId = "-4131952480";

        // Формирование текста сообщения
        const messageText = `Новая заявка:\nИмя: ${formData.name}\nТелефон: ${formData.phone}\nE-mail: ${formData.email} \nПричина обращения: ${this.appeal}`;

        // Отправка сообщения в чат Telegram
        axios
          .post(`https://api.telegram.org/bot${botToken}/sendMessage`, {
            chat_id: chatId,
            text: messageText,
          })
          .then(() => {
            this.isOpen = true;
          });
      } else {
        // Установка ошибок для пустых полей
        this.errors.name = !this.name;
        this.errors.phone = !this.phone;
        this.errors.email = !this.email;
      }
    },
    switchActiveTab(tab) {
      const index = this.tabs.findIndex((item) => item === tab);

      // Если элемент найден, изменяем значение свойства active
      if (index !== -1) {
        this.tabs[index].active = !this.tabs[index].active;
      }
      console.log(index);
    },
    scrollToElement(elementId) {
      this.isMobileMenuOpen = false;
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    },
    scrollToElementDesktop(elementId) {
      this.isMobileMenuOpen = false;
      const element = document.getElementById(elementId);

      if (element) {
        const rect = element.getBoundingClientRect();
        const offset = rect.top + window.scrollY;

        window.scrollTo({
          top: offset,
          behavior: "smooth",
        });
      } else {
        console.warn(`Element with ID "${elementId}" not found.`);
      }
    },
  },
};
</script>
<style>
.no-scroll {
  overflow: hidden;
}
</style>
