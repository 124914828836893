<template>
  <Listbox v-model="selectedAppeal">
    <div class="mt-1">
      <ListboxButton
        class="h-14 flex flex-row justify-between items-center rounded-[4px] p-4 w-full cursor-default bg-white py-2 px-4 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
      >
        <span class="block truncate">{{ selectedAppeal.name }}</span>
        <IconSelector stroke="{2}" class="text-black h-5 w-5" />
      </ListboxButton>

      <transition
        leave-active-class="transition duration-100 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="mt-1 max-h-60 w-auto overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm"
        >
          <ListboxOption
            v-slot="{ active, selected }"
            v-for="appeal in appeals"
            :key="appeal.name"
            :value="appeal"
            as="template"
          >
            <li
              :class="[
                active ? 'bg-gray-600 text-white' : 'text-gray-900',
                'relative cursor-default select-none px-4 py-2',
              ]"
            >
              <span
                :class="[
                  selected ? 'font-medium' : 'font-normal',
                  'block truncate',
                ]"
                >{{ appeal.name }}</span
              >
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script setup>
import { ref, watchEffect, defineEmits } from "vue";
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from "@headlessui/vue";
import { IconSelector } from "@tabler/icons-vue";

const appeals = [
  { name: "Записаться на приём" },
  { name: "Юр. Консультация" },
  { name: "Сопровождение сделки" },
  { name: "Оформление притензии" },
  { name: "Представительство в суде" },
  { name: "Услуги адвоката" },
];
const selectedAppeal = ref(appeals[0]);

const emit = defineEmits(["change"]);

watchEffect(() => {
  if (selectedAppeal.value) {
    emit("change", selectedAppeal.value);
  }
});
</script>
